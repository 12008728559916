import React, { useState } from 'react';

import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import {
  SubscribersService,
  UnblockingReason,
  unblockingReasons,
} from '../../../../services/subscribers';
import toast from 'react-hot-toast';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import { FormControl, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useStyles } from '../useStyles';
import ButtonGroup from '../ButtonGroup';

const UnblockPublicChargingAccessDialog: React.FC<IPropsFromState> = ({
  subscriberState,
  getSubscriberOnlyRequest,
}) => {
  const { t, terms } = useTypedTranslation();
  const [unblockingReason, setUnblockingReason] = useState<UnblockingReason>(
    'UNBLOCKED_MANUAL_FRAUD_REVIEW' // set this as initial value because it was used all the time
  );

  const classes = useStyles();

  const subscriber = subscriberState.data;

  const onConfirm = async () => {
    if (subscriber?.blocked) {
      try {
        await SubscribersService.unblockPublicChargingAccess(
          subscriber.id,
          unblockingReason
        );
        getSubscriberOnlyRequest({
          userId: subscriber.iam_id,
        });
      } catch (err) {
        toast.error(t(terms.general_errors.generic));
        throw new Error(
          'Unexpected error. Could not re-activate public charging access.'
        );
      }
    }
  };

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={t(
            terms.public_charging_access.actions.re_activate.headline
          )}
        />
      </div>
      <div className={classes.infoText}>
        {t(terms.public_charging_access.actions.re_activate.text_info)}
        <br />
        <br />
        {t(terms.public_charging_access.actions.re_activate.text)}
      </div>
      <FormControl className={classes.form}>
        <InputLabel id='selected-unblocking-reason-label'>
          Selected unblocking reason
        </InputLabel>
        <Select
          labelId={'selected-unblocking-reason-label'}
          id={'unblocking-reason-select'}
          value={unblockingReason}
          onChange={(event) =>
            setUnblockingReason(event.target.value as UnblockingReason)
          }
          data-testid={'unblocking-reason-select'}
        >
          {Object.entries(unblockingReasons).map(([reason, text]) => (
            <MenuItem key={reason} value={reason}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonGroup confirmAction={async () => onConfirm()} />
    </div>
  );
};

export default connector(UnblockPublicChargingAccessDialog);
