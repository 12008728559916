import React from 'react';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../shared/StatusIndicator/BasicStatus';
import { useTheme } from '@mui/material/styles';

export type PublicChargingAccessStatusIndicatorProps = {
  isBlocked: boolean;
};

export const PublicChargingAccessStatusIndicator = ({
  isBlocked,
}: PublicChargingAccessStatusIndicatorProps) => {
  const { t, terms } = useTypedTranslation();
  const theme = useTheme();

  const valueMapper = (blocked: boolean): IMappedStatus =>
    blocked
      ? {
          color: theme.colorAlertPrimary,
          label: t(terms.public_charging_access.status.blocked),
        }
      : {
          color: theme.colorAccent,
          label: t(terms.public_charging_access.status.unblocked),
        };

  return <BasicStatus status={isBlocked} valueMapper={valueMapper} />;
};
