import React from 'react';
import { Dialog } from '@mui/material';
import { IChargingRecordViewModel } from '../../../../../types/chargingRecords';
import useStyles from './useStyles';
import { formatUnit } from '../../../../../utils/numbers';

export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
  chargingSession: IChargingRecordViewModel;
}

export const PriceDialog: React.FC<IOwnProps> = ({
  open,
  onClose,
  chargingSession,
}) => {
  const classes = useStyles();

  const currency = chargingSession.currency;
  const formatCurrency = (value: number | undefined) =>
    formatUnit(value ?? 0, currency);

  const getSellingPrice = (): number => {
    const totalPrice = chargingSession.price || 0;
    const discount = chargingSession.discount || 0;
    const vatAmount = chargingSession.vat_amount || 0;

    const sellingPrice = totalPrice + discount - vatAmount;

    return sellingPrice;
  };

  const getPercentFromDecimal = (vatDecimal: number): number => {
    return vatDecimal * 100;
  };

  const getRate = (price = 0, priceExchanged = 1): number => {
    return price / priceExchanged;
  };

  const hasBlockingFee = (cdr: IChargingRecordViewModel) => {
    return (
      cdr.blocking_fee !== undefined &&
      cdr.blocking_fee_duration_in_minutes !== undefined
    );
  };

  const getChosenCpoPriceWithCurrency = () => {
    const isGuaranteedPrice =
      chargingSession.cpo_pricing_source === 'guaranteed_cpo_price';
    const chosenCpoPrice = isGuaranteedPrice
      ? chargingSession.guaranteed_cpo_price!
      : chargingSession.cpo_price;

    const mixedCurrency = isGuaranteedPrice
      ? chargingSession.guaranteed_cpo_price_currency
      : chargingSession.cpo_price_currency;

    return formatUnit(chosenCpoPrice, mixedCurrency ?? '?');
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='price-info-dialog'
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <div className={classes.customerPriceWrapper}>
        <span className={classes.title}>Customer Price</span>
        <div className={classes.itemWrapper}>
          <span className={classes.itemProperty}>Selling Price</span>
          <span className={classes.itemValue} data-testid='selling-price'>
            {formatCurrency(getSellingPrice())}
          </span>
        </div>
        <div className={classes.itemWrapper}>
          <span className={classes.itemProperty}>
            Taxation {getPercentFromDecimal(chargingSession.vat_percentage)}%
          </span>
          <span className={classes.itemValue} data-testid='taxation'>
            {formatCurrency(chargingSession.vat_amount)}
          </span>
        </div>
        <div
          data-testid='discount-wrapper'
          className={`${classes.discountWrapper} ${
            !chargingSession.discount ? classes.greyedOut : ''
          }`}
        >
          <span className={classes.itemProperty}>Discount</span>
          <span className={classes.itemValue} data-testid='discount'>
            - {formatCurrency(chargingSession.discount)}
          </span>
        </div>
        <div className={classes.totalWrapper}>
          <span className={classes.itemValue} data-testid='total-price'>
            {formatCurrency(chargingSession.price ?? 0)}
          </span>
        </div>
      </div>
      <div className={classes.priceIncludesWrapper}>
        <span className={classes.title}>Includes</span>
        <div
          data-testid='blocking-fee-wrapper'
          className={`${classes.itemWrapper} ${
            hasBlockingFee(chargingSession) ? '' : classes.greyedOut
          }`}
        >
          <span className={classes.itemProperty}>
            Time-based Fee (
            {chargingSession.blocking_fee_duration_in_minutes ?? 0} min)
          </span>
          <span className={classes.itemValue} data-testid='blocking-fee'>
            {formatCurrency(chargingSession.blocking_fee)}
          </span>
        </div>
      </div>
      <div className={classes.buyingPriceWrapper}>
        <span className={classes.title}>Buying Price</span>
        <div className={classes.itemWrapper}>
          <span className={classes.itemProperty}>Price in local currency</span>
          <span className={classes.itemValue} data-testid='chosen-cpo-price'>
            {getChosenCpoPriceWithCurrency()}
          </span>
        </div>
        {!chargingSession.guaranteed_cpo_price &&
          chargingSession.cpo_price_currency !== chargingSession.currency && (
            <>
              <div className={classes.itemWrapper}>
                <span className={classes.itemProperty}>
                  Price in target currency
                </span>
                <span
                  className={classes.itemValue}
                  data-testid='exchange-price'
                >
                  {formatCurrency(chargingSession.cpo_price_exchanged)}
                </span>
              </div>
              <div className={classes.itemWrapper}>
                <span className={classes.itemProperty}>
                  Exchange rate target currency
                </span>
                <span className={classes.itemValue} data-testid='exchange-rate'>
                  {formatCurrency(
                    getRate(
                      chargingSession.cpo_price,
                      chargingSession.cpo_price_exchanged || 1
                    )
                  )}
                </span>
              </div>
            </>
          )}
      </div>
    </Dialog>
  );
};
