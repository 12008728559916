import {
  ICardOrderModel,
  IRFIDCardModel,
  ISubscriberModel,
  ISubscriptionModel,
} from '../types/subscriber';
import { ITariffModel } from '../types/tariff';

import ApiService from './api';
import {
  ICDRMgmtResponse,
  IChargingSessionModel,
} from '../types/chargingSession';

interface ISubscriberRequestParams {
  userId: string;
}

interface ISubscriptionRequestParams {
  subscriberId: string;
}

interface ICardOrderRequestParams {
  subscriberId: string;
}

interface IBlockPublicChargingAccessRequest {
  subscriber_ids: string[];
  blocking_reason: BlockingReason;
}

interface IUnblockPublicChargingAccessRequest {
  subscriber_ids: string[];
  unblocking_reason: UnblockingReason;
}

const getById = async ({
  userId,
}: ISubscriberRequestParams): Promise<ISubscriberModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/iamid/${userId}`
  );
  const response = await apiService.query();
  return response.data;
};

const getChargeRecord = async (
  externalCdrId: string,
  cpoPartyId: string,
  cpoCountryCode: string
): Promise<ICDRMgmtResponse> => {
  const params = new URLSearchParams();
  params.append('externalCdrId', externalCdrId);
  params.append('cpoPartyId', cpoPartyId);
  params.append('cpoCountryCode', cpoCountryCode);

  const apiService = new ApiService(
    `/mgmt/msp-cdr-rating/v1/chargerecords/ext?${params.toString()}`
  );
  const response = await apiService.query<ICDRMgmtResponse>();
  return response.data;
};

const getAllChargeRecords = async (
  subscriber_id: string
): Promise<IChargingSessionModel[]> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriber_id}/chargerecords`,
    {
      data: {
        limit: 50,
      },
    }
  );
  const response = await apiService.query();
  let data = response.data;

  const totalCount = response.headers['x-total-count'];

  for (let i = 50; i <= totalCount; i = i + 50) {
    const newApiService = new ApiService(
      `/mgmt/customer/v1/subscribers/${subscriber_id}/chargerecords`,
      {
        data: {
          limit: 50,
          offset: i,
        },
      }
    );
    const newResponse = await newApiService.query();
    data = [...data, ...newResponse.data];
  }

  return data;
};

interface IRFIDCardOrderRequest {
  subscriberId: string;
}

const getRFIDCards = async ({
  subscriberId,
}: IRFIDCardOrderRequest): Promise<IRFIDCardModel[]> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriberId}/rfidcards`
  );
  const response = await apiService.query();
  return response.data;
};

const getRFIDCardOrders = async ({
  subscriberId,
}: ICardOrderRequestParams): Promise<ICardOrderModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriberId}/rfidcardorders`
  );
  const response = await apiService.query();
  return response.data;
};

const getSubscriptions = async ({
  subscriberId,
}: ISubscriptionRequestParams): Promise<ISubscriptionModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriberId}/subscriptions`
  );
  const response = await apiService.query();
  return response.data;
};

const getTariffs = async (): Promise<ITariffModel[]> => {
  const apiService = new ApiService(`/mgmt/customer/v1/tariffs`);
  const response = await apiService.query();
  return response.data;
};

// only manual unblocking reasons are allowed in Contact Center, a subset of all possible reasons
export const unblockingReasons = {
  UNBLOCKED_MANUAL_FRAUD_REVIEW: 'Fraud Review',
  UNBLOCKED_MANUAL_TERMS_OF_USE_ACCEPTED: 'Terms of Use accepted',
  UNBLOCKED_MANUAL_ALL_PAID: 'All invoices paid',
};

export type UnblockingReason = keyof typeof unblockingReasons;

const unblockPublicChargingAccess = async (
  subscriberId: string,
  unblockingReason: UnblockingReason
): Promise<{ subscriber_id: string }> => {
  const requestBody: IUnblockPublicChargingAccessRequest = {
    subscriber_ids: [subscriberId],
    unblocking_reason: unblockingReason,
  };
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/public-charging-access/unblock`,
    {
      method: 'POST',
      data: requestBody,
    }
  );
  const response = await apiService.query();
  const unblockedSubscriberIds = response.data.subscriber_ids;
  return { subscriber_id: unblockedSubscriberIds[0] };
};

// only manual blocking reasons are allowed in Contact Center, a subset of all possible reasons
export const blockingReasons = {
  BLOCKED_MANUAL_FRAUD_REVIEW: 'Fraud Review',
  BLOCKED_MANUAL_DUNNING_FRAUD: 'Dunning',
  BLOCKED_MANUAL_DUNNING_DEBT_COLLECTION: 'Debt Collection',
  BLOCKED_MANUAL_MISUSE: 'Misuse',
  // BLOCKED_MANUAL_TERMS_OF_USE_NOT_ACCEPTED: 'Terms of Use not accepted', // not accessible for CuCa agents
};

export type BlockingReason = keyof typeof blockingReasons;

const blockPublicChargingAccess = async (
  subscriberId: string,
  blockingReason: BlockingReason
): Promise<{ subscriber_id: string }> => {
  const requestBody: IBlockPublicChargingAccessRequest = {
    subscriber_ids: [subscriberId],
    blocking_reason: blockingReason,
  };
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/public-charging-access/block`,
    {
      method: 'POST',
      data: requestBody,
    }
  );
  const response = await apiService.query();
  const blockedSubscriberIds = response.data.blocked_subscriber_ids;
  return { subscriber_id: blockedSubscriberIds[0] };
};

export const SubscribersService = {
  getById,
  getAllChargeRecords,
  getChargeRecord,
  getRFIDCards,
  getRFIDCardOrders,
  getSubscriptions,
  getTariffs,
  unblockPublicChargingAccess,
  blockPublicChargingAccess,
};
