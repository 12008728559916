export const terms = {
  action_cards: {
    ca_sessions: {
      details: 'action-cards.ca_sessions.details',
      headline: 'action-cards.ca_sessions.headline',
      session: {
        duration: 'action-cards.ca_sessions.session.duration',
        evse_id: 'action-cards.ca_sessions.session.evse_id',
        last_updated: 'action-cards.ca_sessions.session.last_updated',
        start_time: 'action-cards.ca_sessions.session.start_time',
        status: 'action-cards.ca_sessions.session.status',
      },
    },
    general: {
      show_all: 'action-cards.general.show-all',
      show_less: 'action-cards.general.show-less',
      show_more: 'action-cards.general.show-more',
    },
    invoices: {
      details: 'action-cards.invoices.details',
      document_date: 'action-cards.invoices.document-date',
      headline: 'action-cards.invoices.headline',
      invoice_id: 'action-cards.invoices.invoice-id',
      total_amount: 'action-cards.invoices.total-amount',
    },
    rfid_cards: {
      headline: 'action-cards.rfid-cards.headline',
      order: {
        title: 'action-cards.rfid-cards.order.title',
      },
    },
    sessions: {
      headline: 'action-cards.sessions.headline',
    },
  },
  card_details: {
    actions: {
      activate: {
        bullets: 'card-details.actions.activate.bullets',
        bullets_subscription:
          'card-details.actions.activate.bullets-subscription',
        menu: 'card-details.actions.activate.menu',
        title: 'card-details.actions.activate.title',
      },
      archive: {
        bullets: 'card-details.actions.archive.bullets',
        menu: 'card-details.actions.archive.menu',
        title: 'card-details.actions.archive.title',
      },
      deactivate: {
        bullets: 'card-details.actions.deactivate.bullets',
        menu: 'card-details.actions.deactivate.menu',
        title: 'card-details.actions.deactivate.title',
      },
      remove: {
        bullets: 'card-details.actions.remove.bullets',
        menu: 'card-details.actions.remove.menu',
        title: 'card-details.actions.remove.title',
      },
    },
  },
  customer_verification: {
    user_search: {
      continue_button: 'customer-verification.user-search.continue-button',
      match_label_customer_number:
        'customer-verification.user-search.match-label-customer-number',
      match_label_emaid: 'customer-verification.user-search.match-label-emaid',
      match_label_rfid_card_number:
        'customer-verification.user-search.match-label-rfid-card-number',
      no_users: 'customer-verification.user-search.no-users',
      search_label: 'customer-verification.user-search.search-label',
      subtitle: 'customer-verification.user-search.subtitle',
      title: 'customer-verification.user-search.title',
    },
  },
  general: {
    are_you_sure: 'general.are-you-sure',
    cancel: 'general.cancel',
    close: 'general.close',
    confirm: 'general.confirm',
  },
  general_errors: {
    could_not_be_loaded: 'general-errors.could-not-be-loaded',
    generic: 'general-errors.generic',
    prevent_subscription: 'general-errors.prevent-subscription',
  },
  invoice: {
    table: {
      columns: {
        amount: 'invoice.table.columns.amount',
        credit_notes: 'invoice.table.columns.credit-notes',
        document_date: 'invoice.table.columns.document-date',
        invoice_id: 'invoice.table.columns.invoice-id',
        payment_status: 'invoice.table.columns.payment-status',
      },
    },
  },
  order_card_dialog: {
    additional_information: 'order-card-dialog.additional-information',
    fields: {
      address_line_1: 'order-card-dialog.fields.address-line-1',
      address_line_2: 'order-card-dialog.fields.address-line-2',
      city: 'order-card-dialog.fields.city',
      country: 'order-card-dialog.fields.country',
      name: 'order-card-dialog.fields.name',
      other_reason: 'order-card-dialog.fields.other-reason',
      reason: 'order-card-dialog.fields.reason',
      reason_options: {
        defective: 'order-card-dialog.fields.reason-options.defective',
        lost: 'order-card-dialog.fields.reason-options.lost',
        not_arrived: 'order-card-dialog.fields.reason-options.not-arrived',
        other: 'order-card-dialog.fields.reason-options.other',
      },
      state: 'order-card-dialog.fields.state',
      zip: 'order-card-dialog.fields.zip',
    },
    headline: 'order-card-dialog.headline',
    no_subscriber: {
      headline: 'order-card-dialog.no-subscriber.headline',
      sub_headline: 'order-card-dialog.no-subscriber.sub-headline',
    },
    request_failed: 'order-card-dialog.request-failed',
    shipping_address: 'order-card-dialog.shipping-address',
    sub_headline: 'order-card-dialog.sub-headline',
    submit: 'order-card-dialog.submit',
    success: {
      headline: 'order-card-dialog.success.headline',
    },
  },
  pair_rfid_card_and_subscription: {
    headline: 'pair-rfid-card-and-subscription.headline',
    rfid_card_input: 'pair-rfid-card-and-subscription.rfid-card-input',
    sub_headline: 'pair-rfid-card-and-subscription.sub-headline',
    submit: {
      main_text: 'pair-rfid-card-and-subscription.submit.main-text',
      small_text: 'pair-rfid-card-and-subscription.submit.small-text',
    },
    warnings: {
      no_active_subscription:
        'pair-rfid-card-and-subscription.warnings.no-active-subscription',
    },
  },
  payment_methods: {
    bank_name: 'payment-methods.bank-name',
    card_issuer: 'payment-methods.card-issuer',
    email: 'payment-methods.email',
    expiry_date: 'payment-methods.expiry-date',
    status: 'payment-methods.status',
    title: 'payment-methods.title',
  },
  public_charging: {
    billing_address: 'public-charging.billing-address',
    box_title: 'public-charging.box-title',
    linked_charging_card: 'public-charging.linked-charging-card',
    preliminary_end: 'public-charging.preliminary-end',
    signup_date: 'public-charging.signup-date',
    tariff_name: 'public-charging.tariff-name',
    upcoming_tariff: 'public-charging.upcoming-tariff',
  },
  public_charging_access: {
    actions: {
      block: {
        headline: 'public-charging-access.actions.block.headline',
        text: 'public-charging-access.actions.block.text',
      },
      re_activate: {
        headline: 'public-charging-access.actions.re-activate.headline',
        text_info: 'public-charging-access.actions.re-activate.text-info',
        text: 'public-charging-access.actions.re-activate.text',
      },
    },
    blocking_log: {
      headline: 'public-charging-access.blocking-log.headline',
      columns: {
        blocking_reason:
          'public-charging-access.blocking-log.columns.blocking-reason',
        blocking_start:
          'public-charging-access.blocking-log.columns.blocking-start',
        blocking_status:
          'public-charging-access.blocking-log.columns.blocking-status',
      },
    },
    description_charging: 'public-charging-access.description-charging',
    description_rfid: 'public-charging-access.description-rfid',
    status: {
      unblocked: 'public-charging-access.status.unblocked',
      blocked: 'public-charging-access.status.blocked',
    },
    status_label: 'public-charging-access.status-label',
  },
  refunds: {
    add_cdr_link: 'refunds.add-cdr-link',
    add_headline: 'refunds.add-headline',
    add_subscription_link: 'refunds.add-subscription-link',
    credit_note: {
      headline: 'refunds.credit-note.headline',
    },
    form: {
      add_item: 'refunds.form.add-item',
      chargeRecordDialogText: 'refunds.form.chargeRecordDialogText',
      distribution: {
        headline: 'refunds.form.distribution.headline',
      },
      entries: {
        headline: 'refunds.form.entries.headline',
        noEntries: 'refunds.form.entries.noEntries',
        totalAmount: 'refunds.form.entries.totalAmount',
      },
      error: 'refunds.form.error',
      fields: {
        amount: 'refunds.form.fields.amount',
        reimbursement_amount: 'refunds.form.fields.reimbursement-amount',
      },
      headline: 'refunds.form.headline',
      submit: 'refunds.form.submit',
      subscriptionDialogText: 'refunds.form.subscriptionDialogText',
      success: 'refunds.form.success',
    },
    overview: {
      fields: {
        amount: 'refunds.overview.fields.amount',
        credit_note_number: 'refunds.overview.fields.credit-note-number',
        current_state: 'refunds.overview.fields.current-state',
        document_date: 'refunds.overview.fields.document-date',
        payment_status: 'refunds.overview.fields.payment-status',
      },
      headline: 'refunds.overview.headline',
    },
    show_headline: 'refunds.show-headline',
    show_link: 'refunds.show-link',
  },
  shared_components: {
    date_picker: {
      format: {
        mask: 'shared-components.date-picker.format.mask',
        technical: 'shared-components.date-picker.format.technical',
      },
    },
  },
  subscription: {
    labels: {
      inactivation_reason: 'subscription.labels.inactivation-reason',
      revocation_reason: 'subscription.labels.revocation-reason',
      status: 'subscription.labels.status',
    },
  },
  subscription_information: {
    terminate_active_subscription_error: {
      headline:
        'subscription-information.terminate-active-subscription-error.headline',
      sub_headline:
        'subscription-information.terminate-active-subscription-error.sub-headline',
    },
    terminate_active_subscription_future_date_success:
      'subscription-information.terminate-active-subscription-future-date-success',
    terminate_active_subscription_success: {
      headline:
        'subscription-information.terminate-active-subscription-success.headline',
      sub_headline:
        'subscription-information.terminate-active-subscription-success.sub-headline',
    },
    terminate_subscription: 'subscription-information.terminate-subscription',
    terminate_upcoming_subscription:
      'subscription-information.terminate-upcoming-subscription',
    terminate_upcoming_subscription_error: {
      headline:
        'subscription-information.terminate-upcoming-subscription-error.headline',
      sub_headline:
        'subscription-information.terminate-upcoming-subscription-error.sub-headline',
    },
    terminate_upcoming_subscription_success: {
      headline:
        'subscription-information.terminate-upcoming-subscription-success.headline',
      sub_headline:
        'subscription-information.terminate-upcoming-subscription-success.sub-headline',
    },
  },
  subscription_termination: {
    confirmation: 'subscription-termination.confirmation',
    error_dialog_heading: 'subscription-termination.error-dialog-heading',
    result_dialog_button_back:
      'subscription-termination.result-dialog-button-back',
    result_dialog_button_done:
      'subscription-termination.result-dialog-button-done',
    result_dialog_button_next:
      'subscription-termination.result-dialog-button-next',
    result_dialog_heading_almost_there:
      'subscription-termination.result-dialog-heading-almost-there',
    result_dialog_step_2_title:
      'subscription-termination.result-dialog-step-2-title',
    result_dialog_subheading:
      'subscription-termination.result-dialog-subheading',
    revocation_reason_label: 'subscription-termination.revocation-reason-label',
    select_future_date: 'subscription-termination.select-future-date',
    subscription_status_info_details:
      'subscription-termination.subscription-status-info-details',
    subscription_status_info_terminated:
      'subscription-termination.subscription-status-info-terminated',
    terminate_at_future_date:
      'subscription-termination.terminate-at-future-date',
    terminate_subscription: 'subscription-termination.terminate-subscription',
    terminate_subscription_now:
      'subscription-termination.terminate-subscription-now',
    vin_tenant_warning: 'subscription-termination.vin-tenant-warning',
  },
  update_billing_address: {
    failure: 'update-billing-address.failure',
    fields: {
      address_line_1: 'update-billing-address.fields.address-line-1',
      address_line_2: 'update-billing-address.fields.address-line-2',
      city: 'update-billing-address.fields.city',
      country_code: 'update-billing-address.fields.country-code',
      state: 'update-billing-address.fields.state',
      tax_number: 'update-billing-address.fields.tax-number',
      zip: 'update-billing-address.fields.zip',
    },
    headline: 'update-billing-address.headline',
    sub_headline: 'update-billing-address.sub-headline',
    submit: 'update-billing-address.submit',
    success: {
      headline: 'update-billing-address.success.headline',
      sub_headline: 'update-billing-address.success.sub-headline',
    },
  },
  update_user_profile: {
    failure: 'update-user-profile.failure',
    fields: {
      first_name: 'update-user-profile.fields.first-name',
      last_name: 'update-user-profile.fields.last-name',
    },
    headline: 'update-user-profile.headline',
    sub_headline: 'update-user-profile.sub-headline',
    submit: 'update-user-profile.submit',
    success: {
      headline: 'update-user-profile.success.headline',
      sub_headline: 'update-user-profile.success.sub-headline',
    },
  },
  user_profile: {
    box_title: 'user-profile.box-title',
    customer_account_created: 'user-profile.customer-account-created',
    customer_last_login: 'user-profile.customer-last-login',
    ecosystem: 'user-profile.ecosystem',
    email: 'user-profile.email',
    name: 'user-profile.name',
    signup_country: 'user-profile.signup-country',
  },
  vin_eligibility: {
    vin_overview: {
      associated_subscriptions:
        'vin-eligibility.vin-overview.associated-subscriptions',
      associated_users: 'vin-eligibility.vin-overview.associated-users',
      customer_number: 'vin-eligibility.vin-overview.customer-number',
      eligible_tariffs: 'vin-eligibility.vin-overview.eligible-tariffs',
      email: 'vin-eligibility.vin-overview.email',
      iam_user_id: 'vin-eligibility.vin-overview.iam-user-id',
      paired_subscription: 'vin-eligibility.vin-overview.paired-subscription',
      status: 'vin-eligibility.vin-overview.status',
      subscription_notice: 'vin-eligibility.vin-overview.subscription-notice',
    },
    vin_search: {
      error: 'vin-eligibility.vin-search.error',
      headline: 'vin-eligibility.vin-search.headline',
      instructions: 'vin-eligibility.vin-search.instructions',
      not_vin: 'vin-eligibility.vin-search.not-vin',
      search_label: 'vin-eligibility.vin-search.search-label',
      warning_text_seat: 'vin-eligibility.vin-search.warning-text-seat',
      warning_text_skoda: 'vin-eligibility.vin-search.warning-text-skoda',
    },
  },
};

export type Terms = typeof terms;
