import React from 'react';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import { FormControl, InputLabel } from '@mui/material';
import {
  SubscribersService,
  BlockingReason,
  blockingReasons,
} from '../../../../services/subscribers';
import { toast } from 'react-hot-toast';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import { useStyles } from '../useStyles';
import ButtonGroup from '../ButtonGroup';

const BlockPublicChargingAccessDialog: React.FC<IPropsFromState> = ({
  subscriberState,
  getSubscriberOnlyRequest,
}) => {
  const { t, terms } = useTypedTranslation();
  const [blockingReason, setBlockingReason] = React.useState<BlockingReason>(
    'BLOCKED_MANUAL_FRAUD_REVIEW' // set this as initial value because it was used all the time
  );

  const classes = useStyles();

  const subscriber = subscriberState.data;

  const onConfirm = async () => {
    if (subscriber && !subscriber.blocked) {
      try {
        await SubscribersService.blockPublicChargingAccess(
          subscriber.id,
          blockingReason
        );
        getSubscriberOnlyRequest({
          userId: subscriber.iam_id,
        });
      } catch (err) {
        toast.error(t(terms.general_errors.generic));
        throw new Error(
          'Unexpected error. Could not block public charging access.'
        );
      }
    }
  };

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={t(terms.public_charging_access.actions.block.headline)}
        />
      </div>
      <div className={classes.infoText}>
        {t(terms.public_charging_access.actions.block.text)}
      </div>
      <FormControl className={classes.form}>
        <InputLabel id='selected-blocking-reason-label'>
          Selected blocking reason
        </InputLabel>
        <Select
          labelId={'selected-blocking-reason-label'}
          id={'blocking-reason-select'}
          value={blockingReason}
          onChange={(e) => setBlockingReason(e.target.value as BlockingReason)}
          data-testid={'blocking-reason-select'}
        >
          {Object.entries(blockingReasons).map(([reason, text]) => (
            <MenuItem key={reason} value={reason}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonGroup confirmAction={async () => onConfirm()} />
    </div>
  );
};

export default connector(BlockPublicChargingAccessDialog);
