import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import useStyles from './useStyles';
import useSharedStyles from '../../../shared/useSharedStyles';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import { PriceDialog } from './PriceDialog/PriceDialog';
import { Typography } from '../../../shared/Typography/Typography';

export interface PaymentDetailsProps {
  chargingSession: IChargingRecordViewModel;
}

export const PaymentDetails = ({ chargingSession }: PaymentDetailsProps) => {
  const { price, coupon_reduction, currency } = chargingSession;
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const [isPriceDialogOpen, setIsPriceDialogOpen] = useState(false);

  const formatCurrency = (value: number | undefined) =>
    `${(value ?? 0).toFixed(2).replace('.', ',')} ${currency}`;

  const calculatePricePaid = (): number => {
    if (!coupon_reduction) {
      return price ?? 0;
    }
    if (!price || coupon_reduction > price) {
      return 0;
    }
    return price - coupon_reduction;
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h4'>
            <span data-testid='payment-details-title'>Price and Payment</span>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>
            Price (incl. VAT {chargingSession.vat_percentage * 100}%)
          </Typography>
          <div className={classes.addressElement}>
            <Typography variant='body2' className={classes.headingWithIcon}>
              {formatCurrency(price)}
              <IconButton
                data-testid='price-info-button'
                onClick={() => setIsPriceDialogOpen((prevState) => !prevState)}
                className={classes.infoIcon}
                disableRipple
                disableFocusRipple
                size='large'
              >
                <HelpOutlineIcon />
              </IconButton>
            </Typography>
          </div>
          <PriceDialog
            open={isPriceDialogOpen}
            onClose={() => setIsPriceDialogOpen(false)}
            chargingSession={chargingSession}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Paid via default payment method</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {formatCurrency(calculatePricePaid())}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Paid via multi-purpose voucher</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {formatCurrency(coupon_reduction)}
            </Typography>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default PaymentDetails;
