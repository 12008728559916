import differenceInSeconds from 'date-fns/differenceInSeconds';
import format from 'date-fns/format';
import parseSeconds, { parseSecondsDigital } from './parseSeconds';

export const formatIsoDates = (date: string): string => {
  try {
    return format(new Date(date), `dd.MM.yyyy HH:mm:ss`);
  } catch (_error) {
    return date;
  }
};

export const formatIsoTime = (date: string): string => {
  try {
    return format(new Date(date), `HH:mm`);
  } catch (_error) {
    return date;
  }
};

export const formatIsoDatesNoSeconds = (date: string): string => {
  try {
    return format(new Date(date), `dd.MM.yyyy HH:mm`);
  } catch (_error) {
    return date;
  }
};

export const formatShortDateTime = (date: string): string => {
  try {
    return format(new Date(date), `dd LLL, HH:mm`);
  } catch (_error) {
    return date;
  }
};

export const formatDateNoTime = (date: string): string => {
  try {
    return format(new Date(date), `dd.MM.yyyy`);
  } catch (_error) {
    return date;
  }
};

export const formatTimeSince = (date: string | number | Date): string => {
  const result = differenceInSeconds(new Date(), new Date(date));
  return parseSecondsDigital(result);
};

export const formatDurationInSeconds = (seconds: number): string => {
  // date-fns version is so old that it does not have the formatDuration function
  // instead we rely on this badly named utility function
  // what we really would like is to format a duration with only hours and minutes:
  // const hours = Math.floor(seconds / 3600);
  // const minutes = Math.floor((seconds - hours * 3600) / 60);
  // return `${hours} h ${minutes} m`;
  return parseSeconds(seconds);
};
