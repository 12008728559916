import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';
import { ICDRMgmtResponse } from '../../../../types/chargingSession';
import CpoGroupDialog from './CpoGroupDialog';
import { ChargingCondition } from '../../../../types/chargingCondition';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LegacyBlockingFeeTable from './LegacyBlockingFeeTable';
import { config } from '../../../../config';
import { BlockingFeeTable } from './BlockingFeeTable';
import { formatDurationInSeconds } from '../../../../utils/dates';
import { formatUnit } from '../../../../utils/numbers';

export type BreakdownTableProps = {
  chargeRecord: ICDRMgmtResponse;
  chargingCondition: ChargingCondition;
};

export const BreakdownTable = ({
  chargeRecord,
  chargingCondition,
}: BreakdownTableProps) => {
  const classes = useStyles();
  const [isCpoGroupDialogOpen, setIsCpoGroupDialogOpen] = useState(false);
  const priceBreakdown = chargeRecord.price_breakdown;

  const currency = chargeRecord.blocking_fee_currency;
  const formatCurrency = (value: number) => formatUnit(value, currency);

  const showLegacyBlockingFeeTable =
    config.env === 'prod' &&
    Date.parse(chargeRecord.start_date_time) <
      Date.parse('2025-01-27T00:00:00Z'); // to be discussed

  let fixedConsumptionPrice: number | undefined;

  let billableBlockingPeriod = { start: '', end: '' };

  if (chargeRecord.location_connector_power_type === 'DC') {
    fixedConsumptionPrice = chargingCondition.fixed_pricing_dc_price;
    if (chargingCondition.blocking_fee_dc) {
      billableBlockingPeriod = {
        start: chargingCondition.blocking_fee_dc?.application_start_local_time,
        end: chargingCondition.blocking_fee_dc?.application_end_local_time,
      };
    }
  } else {
    fixedConsumptionPrice = chargingCondition.fixed_pricing_ac_price;
    if (chargingCondition.blocking_fee_ac) {
      billableBlockingPeriod = {
        start: chargingCondition.blocking_fee_ac?.application_start_local_time,
        end: chargingCondition.blocking_fee_ac?.application_end_local_time,
      };
    }
  }

  const consumptionFee = priceBreakdown?.charging_fee?.gross_amount ?? 0;

  const couponReduction = priceBreakdown?.coupon_reduction ?? 0;

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell size='small'>
              <Typography variant='h6'>Item</Typography>
            </TableCell>
            <TableCell align='right' size='small'>
              <Typography variant='h6'>Total</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ alignContent: 'flex-start' }}>
              <Typography variant='subtitle2' sx={{ display: 'inline' }}>
                Energy price according to consumption
              </Typography>
              {chargeRecord.cpo_party_id && (
                <>
                  <IconButton
                    data-testid='price-info-button'
                    onClick={() =>
                      setIsCpoGroupDialogOpen((prevState) => !prevState)
                    }
                    disableRipple
                    disableFocusRipple
                    size='large'
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                  <CpoGroupDialog
                    open={isCpoGroupDialogOpen}
                    onClose={() => setIsCpoGroupDialogOpen(false)}
                    cpoPartId={chargeRecord.cpo_party_id}
                  />
                </>
              )}
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  {formatCurrency(consumptionFee)}
                </Typography>
                <Typography variant='h6'>
                  {chargingCondition.fixed_pricing_unit === 'min' ||
                  chargingCondition.fixed_pricing_unit === 's'
                    ? formatDurationInSeconds(chargeRecord.total_time * 60 * 60)
                    : formatUnit(
                        chargeRecord.total_energy,
                        chargingCondition.fixed_pricing_unit
                      )}
                  {' * '}
                  {formatCurrency(fixedConsumptionPrice)}/
                  {chargingCondition.fixed_pricing_unit}
                </Typography>
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Pricing model</TableCell>
            <TableCell align='right'>
              {chargingCondition.pricingModel === 'markup_pricing'
                ? 'Markup'
                : 'Fixed'}
            </TableCell>
          </TableRow>
          {/* LegacyBlockingFeeTable can be removed after 2025-03-01 */}
          {showLegacyBlockingFeeTable ? (
            <LegacyBlockingFeeTable
              chargeRecord={chargeRecord}
              billableBlockingPeriod={billableBlockingPeriod}
            />
          ) : (
            <BlockingFeeTable chargeRecord={chargeRecord} />
          )}

          <TableRow>
            <TableCell>
              <Typography variant='subtitle2'>Session Fee</Typography>
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  {formatCurrency(chargingCondition.transaction_fee)}
                </Typography>
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography variant='subtitle2'>
                Voucher amount applied
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  - {formatCurrency(couponReduction)}
                </Typography>
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell align='right'>
              <Typography variant='h6'>
                (VAT is included in the total price)
              </Typography>
              <Typography variant='subtitle2'>
                <span style={{ marginRight: '50px' }}>Total</span>
                <span>
                  {formatCurrency(
                    priceBreakdown?.total_price?.gross_amount ?? 0
                  )}
                </span>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default BreakdownTable;
