/**
 * returns a display text for a blocking or unblocking reason
 * @param reason a blocking or unblocking reason from the subscribers blocking log
 */
export const getBlockingLogReasonText = (reason: string): string => {
  // we use a string type for the reason here, because if
  // the backend adds a new reason, we don't want to break the frontend.
  // This is just a convenience function to translate an internal reason to
  // a more human readable display text. If we don't find a good display text
  // we will just return the internal name.

  switch (reason) {
    // blocking reasons
    case 'BLOCKED_MANUAL_FRAUD_REVIEW':
      return 'Fraud Review (manual)';
    case 'BLOCKED_MANUAL_DUNNING_FRAUD':
      return 'Dunning (manual)';
    case 'BLOCKED_MANUAL_MISUSE':
      return 'Misuse (manual)';
    case 'BLOCKED_MANUAL_DUNNING_DEBT_COLLECTION':
      return 'Debt Collection (manual)';
    case 'BLOCKED_MANUAL_TERMS_OF_USE_NOT_ACCEPTED':
      return 'Terms of Use not accepted (manual)';
    case 'BLOCKED_AUTO_FRAUD_DETECTION_HIGH_USAGE':
      return 'High Usage Fraud (automatic)';
    case 'BLOCKED_AUTO_FRAUD_FAILED_PAYMENT':
      return 'Fraudulent Authorization on Payment fail (automatic)';
    case 'BLOCKED_AUTO_FAILED_PAYMENT':
      return 'Open Invoices (automatic)';
    case 'BLOCKED_AUTO_DUNNING_DEBT_COLLECTION':
      return 'Debt Collection (automatic)';
    case 'BLOCKED_AUTO_DUNNING_FRAUD':
      return 'Write-Off (automatic)';

    // unblocking reasons
    case 'UNBLOCKED_MANUAL_FRAUD_REVIEW':
      return 'Fraud Review (manual)';
    case 'UNBLOCKED_MANUAL_TERMS_OF_USE_ACCEPTED':
      return 'Terms of Use accepted (manual)';
    case 'UNBLOCKED_AUTO_ALL_PAID':
      return 'All Invoices paid (automatic)';
    case 'UNBLOCKED_MANUAL_ALL_PAID':
      return 'All Invoices paid (manual)';

    default:
      return reason;
  }
};
