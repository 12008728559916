import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import { formatIsoDates, formatIsoDatesNoSeconds } from '../../../utils/dates';
import { lexicographical } from '../../../utils/comparisonFunctions';
import { parseAddress } from '../../../utils/parseAddress';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { useStyles } from './useStyles';
import useSharedStyles from '../../shared/useSharedStyles';

import { ActionBar } from '../../shared/ActionBar/ActionBar';
import ActionButtons from './ActionButtons';
import CardElement from '../../shared/CardElement/CardElement';
import ChangeBillingModal from './ChangeBillingModal';
import ChargingReadiness from './ChargingReadiness';
import CopyTextFieldList from '../../ui/CopyTextField/CopyTextFieldList';
import { DialogChargingCard } from '../../ChargingCards/DialogChargingCard';
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import EditIcon from '../../../assets/image/edit.svg';
import { IActionItem } from '../../shared/ActionDropdownContainer';
import InformationItem, {
  IInformationItem,
} from '../../shared/InformationItems/InformationItem';
import {
  getSubscriptionStatus,
  IRFIDCardModel,
  ISubscriptionModel,
} from '../../../types/subscriber';
import { ITariffConditionModel, ITariffModel } from '../../../types/tariff';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import { TariffService } from '../../../services/tariffs';
import Tooltip from '../../ui/Tooltip';
import { useTariffsQuery } from '../../../state/queries/tariffs';
import { StatefulInformationItem } from '../../shared/InformationItems/InformationItemWithLoadingState';
import { PaymentMethodStatus } from '../../../types/paymentMethods';
import { SubscriptionStatusComponent } from '../../SubscriptionStatus/SubscriptionStatusComponent';
import { PaymentMethodStatusComponent } from '../../DetailCards/PaymentDetails/CardSection/PaymentMethodStatusComponent';
import { PublicChargingAccessStatusIndicator } from './PublicChargingAccessStatusIndicator/PublicChargingAccessStatusIndicator';
import { Typography } from '../../shared/Typography/Typography';
import { SubscriptionStatusLabel } from '../../shared/Subscription/SubscriptionStatusLabel';
import BlockPublicChargingAccessDialog from './BlockPublicChargingAccessDialog/BlockPublicChargingAccessDialog';
import ReactivatePublicChargingAccessDialog from './UnblockPublicChargingAccessDialog/UnblockPublicChargingAccessDialog';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Body } from '@elli-eco/component-library';
import TableContainer from '@mui/material/TableContainer';
import DialogHeadline from '../../shared/Dialogs/DialogHeadline';
import { getBlockingLogReasonText } from './blockingReasons';

type SubscriptionType = 'upcoming' | 'active';
const hasSubscriptionWithType = (
  subscriptions: ISubscriptionModel[],
  type: SubscriptionType
) => {
  return subscriptions.some((sub) => sub.status === type);
};

const findUpcomingSubscription = (subscriptionData: ISubscriptionModel[]) => {
  return subscriptionData.find((sub) => sub.status === 'upcoming');
};

const sortSubscriptionData = (subscriptionData: ISubscriptionModel[]) => {
  subscriptionData.sort((a, b) => {
    const date1 = new Date(a.created_at);
    const date2 = new Date(b.created_at);
    return lexicographical(date1, date2) * -1;
  });
};

const rowWidthForItem = (item: string) => {
  const fullspaceRows = ['customer-address', 'payment-method'];
  if (fullspaceRows.includes(item)) {
    return 12;
  }
  return 6;
};

export const setAvailableSubscriptionData = (
  subscriptionData: ISubscriptionModel[],
  setSubscriptionData: React.Dispatch<React.SetStateAction<ISubscriptionModel>>
) => {
  const hasActiveSubscription = hasSubscriptionWithType(
    subscriptionData,
    'active'
  );
  if (hasActiveSubscription) {
    const latestActiveSubscription = subscriptionData.find(
      (sub) => sub.status === 'active'
    );
    setSubscriptionData(latestActiveSubscription as ISubscriptionModel);
  } else {
    setSubscriptionData(subscriptionData[0]);
  }
};

export const nullSubscription: ISubscriptionModel = {
  id: '-',
  start_date: '-',
  end_date: '-',
  status: 'inactive',
  subscriber_id: '-',
  base_condition_id: '-',
  expiration_reminder_sent_at: '-',
  created_at: '-',
  updated_at: '-',
  automatic_renewal: true,
  notice_period_end_date: '-',
  tariff_id: '-',
  currency: '-',
  monthly_fee_net_amount: 0,
  monthly_fee_vat_amount: 0,
  monthly_fee_vat_percentage: 0,
  monthly_fee: 0,
  sign_up_fee_net_amount: 0,
  sign_up_fee_vat_amount: 0,
  sign_up_fee_vat_percentage: 0,
  sign_up_fee: 0,
};

export interface IChargingCardDialog {
  rfidUid: string;
  number?: string;
  tag?: string;
}

export type TProps = IPropsFromState & {
  crumbId?: string;
};

export const SubscriptionsUI: React.FC<TProps> = ({
  subscriberState,
  subscriptionState,
  chargingCardState,
  cardOrdersState,
  addBreadcrumb,
  chargingSessionsState,
  invoicesState,
  paymentDetailsState,
}) => {
  const classes = { ...useStyles(), ...useSharedStyles() };
  const { showBlockPublicChargingAccess } = useFlags();
  const { t, terms } = useTypedTranslation();

  const tariffs = useTariffsQuery();

  const subscriber = subscriberState;
  const subscription = subscriptionState;
  const chargingCards = chargingCardState;
  const chargingSessions = chargingSessionsState;
  const orders = cardOrdersState;
  const invoices = invoicesState;
  const paymentMethodsData = paymentDetailsState.data;
  const paymentMethodStatus =
    paymentMethodsData && paymentMethodsData.length > 0
      ? paymentMethodsData[0].statusCode
      : PaymentMethodStatus.Not_Available;
  const isBlocked = Boolean(subscriber.data?.blocked);

  const [subscriptionData, setSubscriptionData] = useState<ISubscriptionModel>(
    nullSubscription
  );
  const [
    upcomingTariff,
    setUpcomingTariff,
  ] = useState<ITariffConditionModel | null>(null);
  const [upcomingSubscriptionId, setUpcomingSubscriptionId] = useState<string>(
    ''
  );
  const [
    subscriptionTariff,
    setSubscriptionTariff,
  ] = useState<ITariffConditionModel | null>(null);

  const [
    subscriptionChargingCard,
    setSubscriptionChargingCard,
  ] = useState<IRFIDCardModel | null>(null);

  const [dialogData, setDialogData] = useState<IChargingCardDialog | null>(
    null
  );

  const [tariff, setTariff] = useState<ITariffModel | null>(null);

  useEffect(() => {
    if (subscriptionData) {
      const getTariff = async () => {
        try {
          const subscriptionTariffData = await TariffService.getByConditionId(
            subscriptionData.tariff_id,
            subscriptionData.base_condition_id
          );
          setSubscriptionTariff(subscriptionTariffData);
        } catch (error) {
          setSubscriptionTariff(null);
        }
      };

      if (
        !tariffs.isLoading &&
        !tariffs.isError &&
        tariffs.data?.length !== 0
      ) {
        setTariff(
          tariffs.data?.find(({ id }) => id === subscriptionData.tariff_id) ||
            null
        );
      }

      if (
        subscriptionData.tariff_id !== '-' &&
        subscriptionData.base_condition_id !== '-'
      ) {
        getTariff();
      }
    }
  }, [subscriptionData, tariffs.data]);

  useEffect(() => {
    if (subscriptionData && chargingCards?.data) {
      const currentCard = chargingCards.data.find(
        (cc) => cc.subscription_id === subscriptionData.id
      );
      setSubscriptionChargingCard(currentCard || null);
      if (currentCard) {
        setDialogData({
          rfidUid: currentCard.id,
          number: currentCard.number,
          tag: currentCard.tag,
        });
      }
    }
  }, [subscriptionData, chargingCards]);

  useEffect(() => {
    if (subscription && subscription.data && subscription.data.length >= 1) {
      sortSubscriptionData(subscription.data);
      setAvailableSubscriptionData(subscription.data, setSubscriptionData);
    } else {
      setSubscriptionData(nullSubscription);
    }
  }, [subscriptionState]);

  useEffect(() => {
    const onSetUpcomingTariff = async (
      subscriptionData: ISubscriptionModel | undefined
    ) => {
      if (subscriptionData === undefined) {
        setUpcomingTariff(null);
        return;
      }
      try {
        const tariff = await TariffService.getByConditionId(
          subscriptionData.tariff_id,
          subscriptionData.base_condition_id
        );
        setUpcomingTariff(tariff);
      } catch (err) {
        setUpcomingTariff(null);
      }
    };

    if (subscription && subscription.data && subscription.data.length >= 1) {
      const hasUpcomingSubscription = hasSubscriptionWithType(
        subscription?.data,
        'upcoming'
      );
      if (hasUpcomingSubscription) {
        const upcomingSubscription = findUpcomingSubscription(
          subscription?.data
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // Due to the hasUpcomingSubscription check we can be sure that upcomingSubscription won't be undefined
        setUpcomingSubscriptionId(upcomingSubscription!.id);
        onSetUpcomingTariff(upcomingSubscription);
      } else {
        setUpcomingTariff(null);
      }
    }
  }, [subscription?.data]);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      level: 1,
    });

  const actionItems: IActionItem[] = [
    {
      icon: (
        <OfflineBoltOutlinedIcon
          style={{
            fontSize: 24,
          }}
        />
      ),
      friendlyText: 'Charging Readiness',
      component: <ChargingReadiness />,
      isFullWidth: true,
    },
    {
      icon: <img src={EditIcon} alt='Edit Billing Address' />,
      friendlyText: 'Edit Billing Address',
      component: <ChangeBillingModal />,
      disabled: !subscriber?.data,
    },
    {
      icon: (
        <img
          src={EditIcon}
          alt={t(terms.public_charging_access.actions.re_activate.headline)}
        />
      ),
      friendlyText: t(
        terms.public_charging_access.actions.re_activate.headline
      ),
      component: <ReactivatePublicChargingAccessDialog />,
      disabled: !subscriber?.data?.blocked,
    },
    ...(showBlockPublicChargingAccess
      ? [
          {
            icon: (
              <img
                src={EditIcon}
                alt={t(terms.public_charging_access.actions.block.headline)}
              />
            ),
            friendlyText: t(
              terms.public_charging_access.actions.block.headline
            ),
            component: <BlockPublicChargingAccessDialog />,
            disabled: !!subscriber?.data?.blocked,
          },
        ]
      : []),
  ];

  const generateTooltipData = (title: string, content?: string) => [
    {
      title,
      text: content || '-',
    },
  ];

  const getStatefulItems = () => {
    return [
      {
        item: {
          id: 'tariff-name',
          headline: (
            <>
              {t(terms.public_charging.tariff_name)}
              <div className={classes.tooltipWrapper}>
                <Tooltip tooltipName='tariff-info'>
                  <CopyTextFieldList
                    textList={generateTooltipData('Tariff Code', tariff?.code)}
                  />
                </Tooltip>
              </div>
            </>
          ),
          content: (
            <>
              {subscriptionTariff ? (
                <Typography
                  classes={{
                    root: classes.textWithLink,
                  }}
                  data-testid='tariff-name'
                  variant='body2'
                  onClick={() =>
                    breadcrumbHandler({
                      component: 'SubscriptionDetails',
                      friendlyText: 'Subscription',
                      id: subscriptionData.id,
                    })
                  }
                >
                  {subscriptionTariff.name}
                </Typography>
              ) : (
                '-'
              )}
            </>
          ),
        },
        query: tariffs,
      },
    ];
  };

  const CustomPaymentMethodStatusLabel: React.FC<{ label: string }> = ({
    label,
  }) => {
    return (
      <Typography
        classes={{
          root: classes.textWithLink,
        }}
        data-testid='payment-method-name'
        variant='body2'
        onClick={() =>
          breadcrumbHandler({
            component: 'PaymentDetails',
            friendlyText: 'Payment Methods',
            id: `paymentDetails-${subscriber.data?.id}`,
            large: true,
          })
        }
        style={{ marginLeft: '4px' }}
      >
        {label}
      </Typography>
    );
  };

  const getItems = (): IInformationItem[] => {
    return [
      {
        id: 'subscription-status-title',
        headline: <SubscriptionStatusLabel subscription={subscriptionData} />,
        content: (
          <SubscriptionStatusComponent
            status={getSubscriptionStatus(subscriptionData)}
          />
        ),
      },
      {
        id: 'signup-date-id',
        headline: <>{t(terms.public_charging.signup_date)}</>,
        content: (
          <Typography variant='body2'>
            {formatIsoDates(subscriptionData.start_date)}
          </Typography>
        ),
      },
      {
        id: 'preliminary-end',
        headline: (
          <Typography data-testid='preliminary-end-title' variant='h6'>
            {t(terms.public_charging.preliminary_end)}
          </Typography>
        ),
        content: (
          <Typography data-testid='preliminary-end' variant='body2'>
            {formatIsoDates(subscriptionData.end_date)}
          </Typography>
        ),
      },
      {
        id: 'customer-address',
        headline: (
          <Typography data-testid='customer-address-title' variant='h6'>
            {t(terms.public_charging.billing_address)}
          </Typography>
        ),
        content: (
          <Typography data-testid='customer-address' variant='body2'>
            {(subscriber?.data && parseAddress(subscriber.data.address)) || '-'}
          </Typography>
        ),
      },
      {
        id: 'linked-charging-cards',
        headline: (
          <>
            {t(terms.public_charging.linked_charging_card)}
            {dialogData && (
              <div className={classes.tooltipWrapper}>
                <Tooltip tooltipName='more-info'>
                  <DialogChargingCard
                    rfidUid={dialogData.rfidUid}
                    cardNumber={dialogData.number}
                    tag={dialogData.tag}
                  />
                </Tooltip>
              </div>
            )}
          </>
        ),
        content: (
          <>
            {subscriptionChargingCard ? (
              <Typography variant='body2'>
                <span
                  data-testid='linked-charging-card'
                  className={classes.chargingCardLink}
                  onClick={() =>
                    breadcrumbHandler({
                      component: 'ChargingCardDetails',
                      friendlyText: 'Charging Card Details',
                      id: subscriptionChargingCard.id,
                    })
                  }
                >
                  {subscriptionChargingCard.number || '-'}
                </span>
              </Typography>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        id: 'upcoming-tariff',
        headline: t(terms.public_charging.upcoming_tariff),
        content: (
          <>
            {upcomingTariff && subscription.data ? (
              <Typography variant='body2'>
                <span
                  data-testid='upcoming-tariff'
                  className={classes.chargingCardLink}
                  onClick={() =>
                    breadcrumbHandler({
                      component: 'SubscriptionDetails',
                      friendlyText: 'Subscription',
                      id: upcomingSubscriptionId,
                    })
                  }
                >
                  {upcomingTariff.name || '-'}
                </span>
              </Typography>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        id: 'payment-method',
        headline: t(terms.payment_methods.title),
        content: (
          <PaymentMethodStatusComponent
            status={paymentMethodStatus}
            CustomLabelComponent={
              paymentMethodsData && paymentMethodsData.length > 0
                ? ({ label }) => (
                    <CustomPaymentMethodStatusLabel
                      label={`${label} - ${paymentMethodsData[0].name}`}
                    />
                  )
                : undefined
            }
          />
        ),
      },
      {
        id: 'public-charging-access-status',
        headline: (
          <div className={classes.labelWrapper}>
            {t(terms.public_charging_access.status_label)}
            <Tooltip tooltipName='blocking-info'>
              {subscriber.data?.blocking_log && (
                <div>
                  <DialogHeadline
                    headline={t(
                      terms.public_charging_access.blocking_log.headline
                    )}
                  />
                </div>
              )}
              <Body data-testid='public-charging-access-description'>
                {t(terms.public_charging_access.description_charging)}
                <br />
                {t(terms.public_charging_access.description_rfid)}
              </Body>
              {subscriber.data?.blocking_log && (
                <div style={{ marginTop: '20px' }}>
                  <TableContainer component={Paper}>
                    <Table aria-label={'blocking-log'}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date and Time</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subscriber.data?.blocking_log
                          .sort(
                            (a, b) =>
                              Date.parse(b.created_at) -
                              Date.parse(a.created_at)
                          )
                          .map((log, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ whiteSpace: 'nowrap' }}>
                                {formatIsoDatesNoSeconds(log.created_at)}
                              </TableCell>
                              <TableCell>
                                <PublicChargingAccessStatusIndicator
                                  isBlocked={log.blocked}
                                />
                              </TableCell>
                              <TableCell>
                                {getBlockingLogReasonText(log.reason)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Tooltip>
          </div>
        ),
        content: <PublicChargingAccessStatusIndicator isBlocked={isBlocked} />,
      },
    ];
  };

  return subscriptionData.id !== '-' ? (
    <CardElement
      title={t(terms.public_charging.box_title)}
      withActions={true}
      actionItems={actionItems}
    >
      <Grid container className={classes.detailWrapper}>
        {getStatefulItems().map((statefulItem) => (
          <Grid
            item
            xs={rowWidthForItem(statefulItem.item.id)}
            key={statefulItem.item.id}
          >
            <StatefulInformationItem
              item={statefulItem.item}
              queryState={statefulItem.query}
            />
          </Grid>
        ))}
        {getItems().map((item) => (
          <Grid item xs={rowWidthForItem(item.id)} key={item.id}>
            <InformationItem item={item} />
          </Grid>
        ))}
      </Grid>

      <Divider className={classes.divider} />
      <ActionBar>
        <ActionButtons
          subscription={subscription}
          chargingCards={chargingCards}
          chargingSessions={chargingSessions}
          orders={orders}
          invoices={invoices}
          breadcrumbHandler={breadcrumbHandler}
        />
      </ActionBar>
    </CardElement>
  ) : null;
};

export default connector(SubscriptionsUI);
